import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { useHistory } from 'react-router-dom';
//my import
import required from '../../common/validators';
import authService from '../../service/sessions/auth.service';


const Authentication = () => {

    const history = useHistory();
    const [state, setState] = useState({username: '', password: ''});

    //const showState = (e) => {
    //    console.log('state', state);
    //}

    const onChangeUsername = (e) => {
        //console.log(e.target.value)
        setState({
            ...state,
            username: e.target.value
        });
    }

    const onChangePassword = (e) => {
        setState({
            ...state,
            password: e.target.value
        });
    }

    const onSubmit = () => { 
        //console.log('submitted credentials', state);
        authService.login(state).then(
            data => {
                //console.log('authenticated data', data)
                if (data.success) {
                    handleSubmit();
                    //window.location.reload();
                }
            }
        );
    }

    const handleSubmit = () => { 
        //showState();
        console.log('Successfully authenticated!');
        history.push('/');
    }

    return (
        <div className="login-body">

            <div className="login-panel"></div>

            <div className="login-content">
                <img src="assets/layout/images/logo-black.png" alt="babylon-layout" />

                <h1><span>SIGN IN</span> TO IPM WEB APP</h1>
                {/*<p>Welcome, please use the form to sign-in.</p>*/}

                <div className="login-input-wrapper">
                    <InputText 
                    className="form-control"
                    name="username"
                    value={state.username}
                    onChange={onChangeUsername}
                    validations={[required]}
                    placeholder="Username" 
                    />
                    <i className="pi pi-user"></i>
                </div>

                <div className="login-input-wrapper">
                    <InputText
                     placeholder="Password" 
                     type="password"
                     className="form-control"
                     name="password"
                     value={state.password}
                     onChange={onChangePassword}
                     validations={[required]}
                    />
                    <i className="pi pi-lock"></i>
                </div>

                {/*<Button label="Sign In" onClick={() => { history.push('/') }} />*/}
                <Button label="Sign In" onClick={onSubmit} />
            </div>
        </div>
    )
}

export default Authentication;
