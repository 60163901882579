import React, { useRef, useState, useEffect } from 'react';
import { classNames } from 'primereact/utils';
import { useHistory } from 'react-router-dom';
//ùy imports
import { Toast } from 'primereact/toast';
import authService from './service/sessions/auth.service';
import getEnv from './common/env';

const AppTopbar = (props) => {

    const history = useHistory();
    const topbarMenuClassName = classNames('topbar-menu fadeInDown', { 'topbar-menu-visible': props.topbarMenuActive });
    const profileItemClassName = classNames('user-profile', { 'active-topmenuitem': props.activeTopbarItem === 'profile' });
    //my attrs
    const toast = useRef(null);
    const [currentUser, setCurrentUser] = useState({});
    const [userGot, setUserGot] = useState(false);

    //my init
    useEffect(() => {
        //console.log(userGot, ' | ', currentUser);
        if (!userGot || !currentUser.id ) {
            setCurrentUser(authService.getCurrentUser());
            //console.log('AppToolbar currentUser', currentUser);
            setUserGot(true);
        }
    }, [currentUser, userGot, ]);
    
    /* const activeTopbarItemClassName = (name) => {
        return name === props.activeTopbarItem ? 'active-topmenuitem' : null;
    } */
    //my callbacks
    const onSignOut = () => {
        authService.logout().then(
            data => {
                console.log('signout data: ', data);
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'logged out!', life: 3000 });
                authService.clearSession();
                history.push('/auth');
            },error => {
                console.log('signout error: ', error);
                authService.clearSession();
                history.push('/auth');
            }
        );
    }

    const isProfilePopup = props.profileMode === 'popup' || props.isHorizontal;

    return (
        <div className="layout-topbar">
            <Toast ref={toast} />
            <button className="layout-topbar-logo p-link" onClick={() => history.push('/')}>
                <img id="layout-topbar-logo" src="assets/layout/images/logo-white.png" alt="babylon-layout" />
            </button>

            <button className="layout-menu-button p-link" onClick={props.onMenuButtonClick}>
                <i className="pi pi-bars"></i>
            </button>

            <button id="topbar-menu-button" className="p-link" onClick={props.onTopbarMenuButtonClick}>
                <i className="pi pi-ellipsis-v"></i>
            </button>

            <ul className={topbarMenuClassName}>
                {
                    isProfilePopup && (
                        <li className={profileItemClassName}>
                            <button className="p-link" onClick={(e) => props.onTopbarItemClick(e, 'profile')}>
                                <img alt="babylon-layout" src={`${getEnv().baseHostUrl}${currentUser.avatar}`} />
                                <span className="topbar-item-name">{`${currentUser.first_name} ${currentUser.last_name}`}</span>
                            </button>

                            <ul className={classNames({ 'fadeInDown': !props.isMobile() })}>
                                <li role="menuitem">
                                    <button className="p-link"onClick={(e) => console.log('profile')}>
                                        <i className="pi pi-user"></i>
                                        <span>Profile</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="p-link">
                                        <i className="pi pi-cog"></i>
                                        <span>Settings</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="p-link">
                                        <i className="pi pi-envelope"></i>
                                        <span>Message</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="p-link">
                                        <i className="pi pi-bell"></i>
                                        <span>Notifications</span>
                                    </button>
                                </li>
                                <li role="menuitem">
                                    <button className="p-link" onClick={onSignOut}>
                                        <i className="pi pi-power-off"></i>
                                        <span>Deconnexion</span>
                                    </button>
                                </li>
                            </ul>
                        </li>
                    )
                }
                {/* <li className={activeTopbarItemClassName('notifications')}>
                    <button className="p-link" onClick={(e) => props.onTopbarItemClick(e, 'notifications')}>
                        <i className="topbar-icon pi pi-calendar"></i>
                        <span className="topbar-item-name">Notifications</span>
                    </button>
                    <ul className={classNames({ 'fadeInDown': !props.isMobile() })}>
                        <li role="menuitem">
                            <button className="p-link">
                                <i className="pi pi-tags"></i>
                                <span>Pending tasks</span>
                                <span className="topbar-submenuitem-badge">6</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button className="p-link">
                                <i className="pi pi-calendar-plus"></i>
                                <span>Meeting today at 3pm</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button className="p-link">
                                <i className="pi pi-download"></i>
                                <span>Download</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button className="p-link">
                                <i className="pi pi-lock"></i>
                                <span>Book flight</span>
                            </button>
                        </li>
                    </ul>
                </li>
                <li className={activeTopbarItemClassName('messages')}>
                    <button className="p-link" onClick={(e) => props.onTopbarItemClick(e, 'messages')}>
                        <i className="topbar-icon pi pi-inbox"></i>
                        <span className="topbar-item-name">Messages</span>
                        <span className="topbar-badge">8</span>
                    </button>
                    <ul className={classNames({ 'fadeInDown': !props.isMobile() })}>
                        <li role="menuitem">
                            <button className="topbar-message p-link">
                                <img src="assets/layout/images/avatar-john.png" alt="babylon-layout" />
                                <span>Give me a call</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button className="topbar-message p-link">
                                <img src="assets/layout/images/avatar-julia.png" alt="babylon-layout" />
                                <span>Reports attached</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button className="topbar-message p-link">
                                <img src="assets/layout/images/avatar-kevin.png" alt="babylon-layout" />
                                <span>About your invoice</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button className="topbar-message p-link">
                                <img src="assets/layout/images/avatar-julia.png" alt="babylon-layout" />
                                <span>Meeting today</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button className="topbar-message p-link">
                                <img src="assets/layout/images/avatar.png" alt="babylon-layout" />
                                <span>Out of office</span>
                            </button>
                        </li>
                    </ul>
                </li>
                <li className={activeTopbarItemClassName('settings')}>
                    <button className="p-link" onClick={(e) => props.onTopbarItemClick(e, 'settings')}>
                        <i className="topbar-icon pi pi-cog"></i>
                        <span className="topbar-item-name">Settings</span>
                    </button>
                    <ul className={classNames({ 'fadeInDown': !props.isMobile() })}>
                        <li role="menuitem">
                            <button className="p-link">
                                <i className="pi pi-pencil"></i>
                                <span>Change Theme</span>
                                <span className="topbar-submenuitem-badge">4</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button className="p-link">
                                <i className="pi pi-star"></i>
                                <span>Favorites</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button className="p-link">
                                <i className="pi pi-lock"></i>
                                <span>Lock Screen</span>
                                <span className="topbar-submenuitem-badge">2</span>
                            </button>
                        </li>
                        <li role="menuitem">
                            <button className="p-link">
                                <i className="pi pi-image"></i>
                                <span>Wallpaper</span>
                            </button>
                        </li>
                    </ul>
                </li> */}
            </ul>
        </div>
    );
}

export default AppTopbar;
