import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import AdheringSructureService from '../../service/cruds/AdheringSructureService';
import IpmService from '../../service/cruds/IpmService';

const AdheringSructurePage = () => {

    let emptyAdhering = {
        id: null,
        name: '',
        phone: '',
        address: '',
        employees_count: 0,
        contribution_rate: 0.0,
        quote_part: 0.0,
        ipm_id: null,
        selectedIpm: null,
    };

    const [adherings, setAdherings] = useState(null);
    const [adheringDialog, setAdheringDialog] = useState(false);
    const [deleteAdheringDialog, setDeleteAdheringDialog] = useState(false);
    const [deleteAdheringsDialog, setDeleteAdheringsDialog] = useState(false);
    const [adhering, setAdhering] = useState(emptyAdhering);
    const [selectedAdherings, setSelectedAdherings] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [ipms, setIpms] = useState([]);

    useEffect(() => {
        const adheringSructureService = new AdheringSructureService();
        const ipmService = new IpmService();
        adheringSructureService.list().then(data => {
            setAdherings(data);
            console.log('adherings data', data);
        });
        ipmService.list().then(data => {
            setIpms(data);
            //console.log('ipms data', data);
        });
    }, []);

    const openNew = () => {
        setAdhering(emptyAdhering);
        setSubmitted(false);
        setAdheringDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setAdheringDialog(false);
    }

    const hideDeleteAdheringDialog = () => {
        setDeleteAdheringDialog(false);
    }

    const hideDeleteAdheringsDialog = () => {
        setDeleteAdheringsDialog(false);
    }

    const formIsValid = () => {
        return Boolean(adhering.name.trim());
    }

    const saveAdhering = () => {
        setSubmitted(true);
        if (formIsValid()){
            const adheringSructureService = new AdheringSructureService();
            let _adherings = [...adherings];
            let _adhering = { ...adhering };
            _adhering.ipm_id = _adhering.selectedIpm?.id;
            if (!adhering.id) {
                adheringSructureService.create(_adhering).then(data => {
                    console.log('data', data);
                    _adherings.push(data);
                    setAdherings(_adherings);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Création réussie!', life: 3000 });
                }).catch(error => {
                    console.log(error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Enregistrement échoué', life: 3000 });
                });
            }
            else {
                adheringSructureService.update(adhering.id, _adhering).then(data => {
                    console.log('data', data);
                    const index = findIndexById(adhering.id);
                    _adherings[index] = _adhering;
                    setAdherings(_adherings);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Modification réussie!', life: 3000 });
                }).catch(error => {
                    console.log(error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Enregistrement échoué', life: 3000 });
                });
            }
            setAdheringDialog(false);
            setAdhering(emptyAdhering);
        }
    }

    const editAdhering = (adhering) => {
        setAdhering({ ...adhering,  selectedIpm: adhering.ipm });
        setAdheringDialog(true);
    }

    const confirmDeleteAdhering = (adhering) => {
        setAdhering(adhering);
        setDeleteAdheringDialog(true);
    }

    const deleteAdhering = () => {
        const adheringSructureService = new AdheringSructureService();
        adheringSructureService.delete(adhering.id).then(data => {
            console.log('data', data);
            let _adherings = adherings.filter(val => val.id !== adhering.id);
            setAdherings(_adherings);          
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Sppression réussie!', life: 3000 });
        }).catch(error => {
            console.log(error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Supression échoué', life: 3000 });
        });
        setDeleteAdheringDialog(false);
        setAdhering(emptyAdhering);  
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < adherings.length; i++) {
            if (adherings[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteAdheringsDialog(true);
    }

    const deleteSelectedAdherings = () => {
        let _adhering = adhering.filter(val => !selectedAdherings.includes(val));
        setAdherings(_adhering);
        setDeleteAdheringsDialog(false);
        setSelectedAdherings(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Adhering Deleted', life: 3000 });
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _adhering = { ...adhering };
        _adhering[`${name}`] = val;
        setAdhering(_adhering);
    }

    const onInputNumberChange = (e, name) => {
        const val = e.value || 0;
        let _adhering = { ...adhering };
        _adhering[`${name}`] = val;
        setAdhering(_adhering);
    }

    const onIpmChange = (e) => {
        setAdhering({...adhering, selectedIpm: e.value});
        //console.log('selected ipm', e.value);
    }

    const ipmOptionTemplate = (option) => {
        return (
            <div className="">
                <div>{option.name}</div>
            </div>
        );
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedAdherings || !selectedAdherings.length} />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nom</span>
                {rowData.name}
            </>
        );
    }

    const phoneBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Téléphone</span>
                {rowData.phone}
            </>
        );
    }

    const addressBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Adresse</span>
                {rowData.address}
            </>
        );
    }

    const employees_countBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nombre d'employés</span>
                {rowData.employees_count}
            </>
        );
    }

    const contribution_rateBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Taux cotisation</span>
                {rowData.contribution_rate}
            </>
        );
    }

    const quote_partBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Quote part</span>
                {rowData.quote_part}
            </>
        );
    }

    const ipmBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Ipm</span>
                {rowData.ipm?.name || ''}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editAdhering(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteAdhering(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Gestion des Adhérants</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const AdheringDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveAdhering} />
        </>
    );
    const deleteAdheringDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteAdheringDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteAdhering} />
        </>
    );
    const deleteAdheringsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteAdheringsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedAdherings} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={adherings} selection={selectedAdherings} onSelectionChange={(e) => setSelectedAdherings(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} adherings"
                        globalFilter={globalFilter} emptyMessage="No adherings found." header={header} responsiveLayout="scroll">
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="name" header="NOM" sortable body={nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '12rem' }}></Column>
                        <Column field="phone" header="TELEPHONE" body={phoneBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                        <Column field="address" header="ADRESSE" sortable body={addressBodyTemplate} headerStyle={{ width: '34%', minWidth: '10rem' }}></Column>
                        <Column field="employees_count" header="NOMBRE EMPLOYES" sortable body={employees_countBodyTemplate} headerStyle={{ width: '34%', minWidth: '10rem' }}></Column>
                        <Column field="contribution_rate" header="TAUX COTISATION" sortable body={contribution_rateBodyTemplate} headerStyle={{ width: '34%', minWidth: '10rem' }}></Column>
                        <Column field="quote_part" header="QUOTE PART" sortable body={quote_partBodyTemplate} headerStyle={{ width: '34%', minWidth: '10rem' }}></Column>
                        <Column field="ipm_id" header="Ipm" sortable body={ipmBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={adheringDialog} style={{ width: '450px' }} header="Formulaire Adhérant" modal className="p-fluid" footer={AdheringDialogFooter} onHide={hideDialog}>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="name">Nom</label>
                                <InputText id="name" value={adhering.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !adhering.name })} />
                                {submitted && !adhering.name && <small className="p-invalid">Name is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="phone">Téléphone</label>
                                <InputText id="phone" value={adhering.phone} onChange={(e) => onInputChange(e, 'phone')}  
                                    required autoFocus className={classNames({ 'p-invalid': submitted && !adhering.phone })} />
                                {submitted && !adhering.name && <small className="p-invalid">Phone is required.</small>}
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="address">Adresse</label>
                            <InputText id="address" value={adhering.address} onChange={(e) => onInputChange(e, 'address')}  
                                required autoFocus className={classNames({ 'p-invalid': submitted && !adhering.address })} />
                            {submitted && !adhering.address && <small className="p-invalid">Address is required.</small>}
                        </div>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="employees_count">Nombre employes</label>
                                <InputNumber id="employees_count" value={adhering.employees_count} onValueChange={(e) => onInputNumberChange(e, 'employees_count')}  
                                    required autoFocus className={classNames({ 'p-invalid': submitted && !adhering.employees_count })} />
                                {submitted && !adhering.employees_count && <small className="p-invalid">Employees count is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="contribution_rate">Taux cotisation</label>
                                <InputNumber id="contribution_rate" value={adhering.contribution_rate} onValueChange={(e) => onInputNumberChange(e, 'contribution_rate')} 
                                    required autoFocus className={classNames({ 'p-invalid': submitted && !adhering.contribution_rate })} />
                                    {submitted && !adhering.contribution_rate && <small className="p-invalid">Contribution rate is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="quote_part">Quote part</label>
                                <InputNumber id="quote_part" value={adhering.quote_part} onValueChange={(e) => onInputNumberChange(e, 'quote_part')}  
                                    required autoFocus className={classNames({ 'p-invalid': submitted && !adhering.quote_part })} />
                                {submitted && !adhering.quote_part && <small className="p-invalid">Quote part is required.</small>}
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="ipm_id">Ipm</label>
                            <Dropdown id="ipm_id" value={adhering.selectedIpm} options={ipms} onChange={onIpmChange} 
                                optionLabel="name" filter showClear filterBy="name"
                                placeholder="Selectionner un ipm" itemTemplate={ipmOptionTemplate}
                                required autoFocus className={classNames({ 'p-invalid': submitted && !adhering.selectedIpm })}/>
                                {submitted && !adhering.selectedIpm && <small className="p-invalid">Ipm is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteAdheringDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteAdheringDialogFooter} onHide={hideDeleteAdheringDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {adhering && <span>Are you sure you want to delete <b>{adhering.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteAdheringsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteAdheringsDialogFooter} onHide={hideDeleteAdheringsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {adhering && <span>Are you sure you want to delete the selected adherings?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AdheringSructurePage, comparisonFn);
