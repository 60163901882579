import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import HospitalService from '../../service/cruds/HospitalService';
import HospitalTypeService from '../../service/cruds/HospitalTypeService';

const HospitalPage = () => {

    let emptyHospital = {
        id: null,
        name: '',
        phone: '',
        address: '',
        care_type: '',
        hospital_type_id: null,
        selectedHospitalType: null,
    };

    const [hospitals, setHospitals] = useState(null);
    const [hospitalDialog, setHospitalDialog] = useState(false);
    const [deleteHospitalDialog, setDeleteHospitalDialog] = useState(false);
    const [deleteHospitalsDialog, setDeleteHospitalsDialog] = useState(false);
    const [hospital, setHospital] = useState(emptyHospital);
    const [selectedHospitals, setSelectedHospitals] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [hospitalTypes, setHospitalTypes] = useState([]);

    useEffect(() => {
        const hospitalService = new HospitalService();
        const hospitalTypeService = new HospitalTypeService();
        hospitalService.list().then(data => {
            setHospitals(data);
            console.log('hospitals data', data);
        });
        hospitalTypeService.list().then(data => {
            setHospitalTypes(data);
            console.log('hospitalTypes data', data);
        });
    }, []);

    const openNew = () => {
        setHospital(emptyHospital);
        setSubmitted(false);
        setHospitalDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setHospitalDialog(false);
    }

    const hideDeleteHospitalDialog = () => {
        setDeleteHospitalDialog(false);
    }

    const hideDeleteHospitalsDialog = () => {
        setDeleteHospitalsDialog(false);
    }

    const formIsValid = () => {
        return Boolean(hospital.name.trim());
    }

    const saveHospital = () => {
        setSubmitted(true);
        if (formIsValid()){
            const hospitalService = new HospitalService();
            let _hospitals = [...hospitals];
            let _hospital = { ...hospital };
            _hospital.hospital_type_id = _hospital.selectedHospitalType?.id;
            if (!hospital.id) {
                hospitalService.create(_hospital).then(data => {
                    console.log('data', data);
                    _hospitals.push(data);
                    setHospitals(_hospitals);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Création réussie!', life: 3000 });
                }).catch(error => {
                    console.log(error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Enregistrement échoué', life: 3000 });
                });
            }
            else {
                hospitalService.update(hospital.id, _hospital).then(data => {
                    console.log('data', data);
                    const index = findIndexById(hospital.id);
                    _hospitals[index] = _hospital;
                    setHospitals(_hospitals);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Modification réussie!', life: 3000 });
                }).catch(error => {
                    console.log(error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Enregistrement échoué', life: 3000 });
                });
            }
            setHospitalDialog(false);
            setHospital(emptyHospital);
        }
    }

    const editHospital = (hospital) => {
        setHospital({ ...hospital, selectedHospitalType: hospital.hospital_type });
        setHospitalDialog(true);
    }

    const confirmDeleteHospital = (hospital) => {
        setHospital(hospital);
        setDeleteHospitalDialog(true);
    }

    const deleteHospital = () => {
        const hospitalService = new HospitalService();
        hospitalService.delete(hospital.id).then(data => {
            console.log('data', data);
            let _hospitals = hospitals.filter(val => val.id !== hospital.id);
            setHospitals(_hospitals);          
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Sppression réussie!', life: 3000 });
        }).catch(error => {
            console.log(error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Supression échoué', life: 3000 });
        });
        setDeleteHospitalDialog(false);
        setHospital(emptyHospital);  
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < hospitals.length; i++) {
            if (hospitals[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteHospitalsDialog(true);
    }

    const deleteSelectedHospitals = () => {
        let _hospitals = hospitals.filter(val => !selectedHospitals.includes(val));
        setHospitals(_hospitals);
        setDeleteHospitalsDialog(false);
        setSelectedHospitals(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Hospital Deleted', life: 3000 });
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _hospital = { ...hospital };
        _hospital[`${name}`] = val;
        setHospital(_hospital);
    }

    const onHospitalTypeChange = (e) => {
        setHospital({...hospital, selectedHospitalType: e.value});
        //console.log('selected hospitalType', e.value);
    }

    const hospitalTypeOptionTemplate = (option) => {
        return (
            <div className="">
                <div>{option.label}</div>
            </div>
        );
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedHospitals || !selectedHospitals.length} />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nom</span>
                {rowData.name}
            </>
        );
    }

    const phoneBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Téléphone</span>
                {rowData.phone}
            </>
        );
    }

    const addressBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Adresse</span>
                {rowData.address}
            </>
        );
    }

    const care_typeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type soin</span>
                {rowData.care_type}
            </>
        );
    }

    const hospitalTypeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type</span>
                {rowData.hospital_type?.label || ''}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editHospital(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteHospital(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Gestion des Hospitaux</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const hospitalDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveHospital} />
        </>
    );
    const deleteHospitalDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteHospitalDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteHospital} />
        </>
    );
    const deleteHospitalsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteHospitalsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedHospitals} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={hospitals} selection={selectedHospitals} onSelectionChange={(e) => setSelectedHospitals(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} hospitals"
                        globalFilter={globalFilter} emptyMessage="No hospitals found." header={header} responsiveLayout="scroll">
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="name" header="NOM" sortable body={nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '12rem' }}></Column>
                        <Column field="phone" header="TELEPHONE" body={phoneBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                        <Column field="address" header="ADRESSE" sortable body={addressBodyTemplate} headerStyle={{ width: '34%', minWidth: '10rem' }}></Column>
                        <Column field="care_type" header="TYPE SOIN" sortable body={care_typeBodyTemplate} headerStyle={{ width: '34%', minWidth: '10rem' }}></Column>
                        <Column field="hospital_type_id" header="TYPE STRUCTURE" sortable body={hospitalTypeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={hospitalDialog} style={{ width: '450px' }} header="Formulaire hospital" modal className="p-fluid" footer={hospitalDialogFooter} onHide={hideDialog}>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="name">Nom</label>
                                <InputText id="name" value={hospital.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !hospital.name })} />
                                {submitted && !hospital.name && <small className="p-invalid">Name is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="phone">Téléphone</label>
                                <InputText id="phone" value={hospital.phone} onChange={(e) => onInputChange(e, 'phone')}  required autoFocus className={classNames({ 'p-invalid': submitted && !hospital.phone })} />
                                {submitted && !hospital.phone && <small className="p-invalid">Phone is required.</small>}
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="address">Adresse</label>
                            <InputText id="address" value={hospital.address} onChange={(e) => onInputChange(e, 'address')} 
                            required autoFocus className={classNames({ 'p-invalid': submitted && !hospital.address })} />
                            {submitted && !hospital.address && <small className="p-invalid">Address is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="care_type">Type soin</label>
                            <InputText id="care_type" value={hospital.care_type} onChange={(e) => onInputChange(e, 'care_type')} 
                            required autoFocus className={classNames({ 'p-invalid': submitted && !hospital.care_type })} />
                            {submitted && !hospital.care_type && <small className="p-invalid">Care type is required.</small>}
                        </div>
                        <div className="field">
                            <label htmlFor="hospital_type_id">Type structure</label>
                            <Dropdown id="hospital_type_id" value={hospital.selectedHospitalType} options={hospitalTypes} onChange={onHospitalTypeChange} 
                                optionLabel="label" filter showClear filterBy="label"
                                placeholder="Selectionner un type de structure" itemTemplate={hospitalTypeOptionTemplate} 
                                required autoFocus className={classNames({ 'p-invalid': submitted && !hospital.selectedHospitalType })}/>
                                {submitted && !hospital.selectedHospitalType && <small className="p-invalid">Hospital Type is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteHospitalDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteHospitalDialogFooter} onHide={hideDeleteHospitalDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {hospital && <span>Are you sure you want to delete <b>{hospital.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteHospitalsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteHospitalsDialogFooter} onHide={hideDeleteHospitalsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {hospital && <span>Are you sure you want to delete the selected products?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(HospitalPage, comparisonFn);
