import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
//my imports
import PharmacyService from '../../service/cruds/PharmacyService';

const PharmacyPage = () => {

    let emptyPharmacy = {
        id: null,
        name: '',
        phone: '',
        address: '',
    };

    const [pharmacys, setPharmacys] = useState(null);
    const [pharmacyDialog, setPharmacyDialog] = useState(false);
    const [deletePharmacyDialog, setDeletePharmacyDialog] = useState(false);
    const [deletePharmacysDialog, setDeletePharmacysDialog] = useState(false);
    const [pharmacy, setPharmacy] = useState(emptyPharmacy);
    const [selectedPharmacys, setSelectedPharmacys] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const pharmacyService = new PharmacyService();
        pharmacyService.list().then(data => {
            setPharmacys(data);
            console.log('pharmacys data', data);
        });
    }, []);

    const openNew = () => {
        setPharmacy(emptyPharmacy);
        setSubmitted(false);
        setPharmacyDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setPharmacyDialog(false);
    }

    const hideDeletePharmacyDialog = () => {
        setDeletePharmacyDialog(false);
    }

    const hideDeletePharmacysDialog = () => {
        setDeletePharmacysDialog(false);
    }

    const formIsValid = () => {
        return Boolean(pharmacy.name.trim());
    }

    const savePharmacy = () => {
        setSubmitted(true);
        if (formIsValid()){
            const pharmacyService = new PharmacyService();
            let _pharmacys = [...pharmacys];
            let _pharmacy = { ...pharmacy };
            if (!pharmacy.id) {
                pharmacyService.create(pharmacy).then(data => {
                    console.log('data', data);
                    _pharmacys.push(data);
                    setPharmacys(_pharmacys);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Création réussie!', life: 3000 });
                }).catch(error => {
                    console.log(error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Enregistrement échoué', life: 3000 });
                });
            }
            else {
                pharmacyService.update(pharmacy.id, _pharmacy).then(data => {
                    console.log('data', data);
                    const index = findIndexById(pharmacy.id);
                    _pharmacys[index] = _pharmacy;
                    setPharmacys(_pharmacys);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Modification réussie!', life: 3000 });
                }).catch(error => {
                    console.log(error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Enregistrement échoué', life: 3000 });
                });
            }
            setPharmacyDialog(false);
            setPharmacy(emptyPharmacy);
        }
    }

    const editPharmacy = (pharmacy) => {
        setPharmacy({ ...pharmacy });
        setPharmacyDialog(true);
    }

    const confirmDeletePharmacy = (pharmacy) => {
        setPharmacy(pharmacy);
        setDeletePharmacyDialog(true);
    }

    const deletePharmacy = () => {
        const pharmacyService = new PharmacyService();
        pharmacyService.delete(pharmacy.id).then(data => {
            console.log('data', data);
            let _pharmacys = pharmacys.filter(val => val.id !== pharmacy.id);
            setPharmacys(_pharmacys);          
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Sppression réussie!', life: 3000 });
        }).catch(error => {
            console.log(error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Supression échoué', life: 3000 });
        });
        setDeletePharmacyDialog(false);
        setPharmacy(emptyPharmacy);  
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < pharmacys.length; i++) {
            if (pharmacys[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeletePharmacysDialog(true);
    }

    const deleteSelectedPharmacys = () => {
        let _pharmacys = pharmacys.filter(val => !selectedPharmacys.includes(val));
        setPharmacys(_pharmacys);
        setDeletePharmacysDialog(false);
        setSelectedPharmacys(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Pharmacy Deleted', life: 3000 });
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _pharmacy = { ...pharmacy };
        _pharmacy[`${name}`] = val;
        setPharmacy(_pharmacy);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedPharmacys || !selectedPharmacys.length} />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nom</span>
                {rowData.name}
            </>
        );
    }

    const phoneBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Téléphone</span>
                {rowData.phone}
            </>
        );
    }

    const addressBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Adresse</span>
                {rowData.address}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editPharmacy(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeletePharmacy(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Gestion des Pharmacies</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const pharmacyDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={savePharmacy} />
        </>
    );
    const deletePharmacyDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePharmacyDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deletePharmacy} />
        </>
    );
    const deletePharmacysDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeletePharmacysDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedPharmacys} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={pharmacys} selection={selectedPharmacys} onSelectionChange={(e) => setSelectedPharmacys(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} pharmacys"
                        globalFilter={globalFilter} emptyMessage="No pharmacys found." header={header} responsiveLayout="scroll">
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="name" header="NOM" sortable body={nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '12rem' }}></Column>
                        <Column field="phone" header="TELEPHONE" body={phoneBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                        <Column field="address" header="ADRESSE" sortable body={addressBodyTemplate} headerStyle={{ width: '34%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={pharmacyDialog} style={{ width: '450px' }} header="Formulaire Pharmacie" modal className="p-fluid" footer={pharmacyDialogFooter} onHide={hideDialog}>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="name">Nom</label>
                                <InputText id="name" value={pharmacy.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !pharmacy.name })} />
                                {submitted && !pharmacy.name && <small className="p-invalid">Name is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="phone">Téléphone</label>
                                <InputText id="phone" value={pharmacy.phone} onChange={(e) => onInputChange(e, 'phone')}  required autoFocus className={classNames({ 'p-invalid': submitted && !pharmacy.phone })} />
                                {submitted && !pharmacy.phone && <small className="p-invalid">Phone is required.</small>}
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="address">Adresse</label>
                            <InputText id="address" value={pharmacy.address} onChange={(e) => onInputChange(e, 'address')}  required autoFocus className={classNames({ 'p-invalid': submitted && !pharmacy.address })} />
                            {submitted && !pharmacy.address && <small className="p-invalid">Address is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deletePharmacyDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePharmacyDialogFooter} onHide={hideDeletePharmacyDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {pharmacy && <span>Are you sure you want to delete <b>{pharmacy.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deletePharmacysDialog} style={{ width: '450px' }} header="Confirm" modal footer={deletePharmacysDialogFooter} onHide={hideDeletePharmacysDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {pharmacy && <span>Are you sure you want to delete the selected products?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(PharmacyPage, comparisonFn);
