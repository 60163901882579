import axios from 'axios';
import getEnv from '../../common/env';

const API_URL =  `${getEnv().baseUrl}/accounts/roles`

export default class RoleService {
  
  getAssinginsRoles() {
    return axios
        .get(API_URL)
        .then((response) => {
            //console.log('response', response)
            return response.data;
        });
  }

}
