import React, { useEffect } from 'react';
import { Route, useLocation, withRouter, useHistory } from 'react-router-dom';
import App from './App';
import Access from './pages/Access';
import Authentication from './pages/sessions/Authentication';
import Error from './pages/Error';
import Login from './pages/Login';
import NotFound from './pages/NotFound';
import Wizard from './pages/Wizard';
import authService from './service/sessions/auth.service';

const AppWrapper = () => {

    const history = useHistory();
    let location = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0)
        //my code
        let handled = false;
        let authToken = '';
        //console.log('Welcome');
        if (!handled) {
            //console.log('bienvenue');
            authToken = authService.getToken();
            if (!authToken) {
                history.push('/auth');
            }
            handled = true
            //console.log('auth state for redirect: ', {handled: handled, authToken: authToken});
        }
    }, [location, history]);

    switch (location.pathname) {
        case '/auth':
            return <Route path="/auth" component={Authentication} />
        case '/login':
            return <Route path="/login" component={Login} />
        case '/error':
            return <Route path="/error" component={Error} />
        case '/notfound':
            return <Route path="/notfound" component={NotFound} />
        case '/access':
            return <Route path="/access" component={Access} />
        case '/wizard':
            return <Route path="/wizard" component={Wizard} />
        default:
            return <App />;
    }
}

export default withRouter(AppWrapper);

//help docs
//https://stackoverflow.com/questions/54954385/react-useeffect-causing-cant-perform-a-react-state-update-on-an-unmounted-comp
