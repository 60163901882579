
const PROD = true //variable used only here

export const LOCAL_URL = 'http://localhost:8000';
export const SERVER_URL = 'http://api-tests.buurngalam.com';    //194.163.44.88
export const VERSION = 'v1'
export const BASE_URL = PROD ? SERVER_URL : LOCAL_URL;

const ENV = {
    hideTuto: PROD,
    baseUrl: BASE_URL + '/' + VERSION,
    baseHostUrl: BASE_URL
}

export default function getEnv(){
    return ENV;
}
