import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { RadioButton } from 'primereact/radiobutton';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
//myImports
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
//import getEnv from '../../common/env';
import AdheringSructureService from '../../service/cruds/AdheringSructureService';
import BeneficiaryService from '../../service/cruds/BeneficiaryService';

const BeneficiaryPage = () => {

    let emptyBeneficiary = {
        id: null,
        avatar: null,
        first_name: '',
        last_name: '',
        sex: 'male',
        address: '',
        phone: '',
        beneficiary_type: '',
        birthday: '',
        birth_place: '',
        adhering_structure_id: null,
        selectedAdhering: null,
        selectedType: null,
    };

    const [beneficiaries, setBeneficiaries] = useState(null);
    const [beneficiaryDialog, setBeneficiaryDialog] = useState(false);
    const [deleteBeneficiaryDialog, setDeleteBeneficiaryDialog] = useState(false);
    const [deleteBeneficiarysDialog, setDeleteBeneficiarysDialog] = useState(false);
    const [beneficiary, setBeneficiary] = useState(emptyBeneficiary);
    const [selectedBeneficiaries, setSelectedBeneficiaries] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    //my attrs
    const [viewDate, setViewDate] = useState(null);
    const [adherings, setAdherings] = useState([]);
    const beneficiaryTypes = [ //'MEMBER', 'SPOUSE', 'CHILD'
        {id: 1, label: 'PARTICIPANT', value: 'member'},
        {id: 2, label: 'CONJOINT(E)', value: 'spouse'},
        {id: 3, label: 'ENFANT', value: 'child'},
    ];
    
    useEffect(() => {
        const beneficiaryService = new BeneficiaryService();
        const adheringSructureService = new AdheringSructureService();
        beneficiaryService.list().then(data => {
            setBeneficiaries(data);
            console.log('beneficiaries data', data);
        });
        adheringSructureService.simple_list().then(data => {
            setAdherings(data);
            console.log('adherings data', data);
        });
    }, []);

    const getSexLabel = (value) => {
        if (value === 'male') {
            return 'Homme';
        } else {
            return 'Femme';
        }
    }

    const openNew = () => {
        setBeneficiary(emptyBeneficiary);
        setSubmitted(false);
        setBeneficiaryDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setBeneficiaryDialog(false);
    }

    const hideDeleteBeneficiaryDialog = () => {
        setDeleteBeneficiaryDialog(false);
    }

    const hideDeleteBeneficiarysDialog = () => {
        setDeleteBeneficiarysDialog(false);
    }

    const formIsValid = () => {
        return Boolean(beneficiary.last_name.trim());
    }

    const saveBeneficiary = () => {
        setSubmitted(true);
        if (formIsValid()){
            //console.log('selectedAdhering', beneficiary.selectedAdhering);
            let _beneficiary = { ...beneficiary, birthday: beneficiary.birthday.toISOString().split('T')[0] };
            _beneficiary.adhering_structure_id = _beneficiary.selectedAdhering?.id;
            _beneficiary.beneficiary_type = _beneficiary.selectedType;
            delete _beneficiary.avatar;
            //console.log('submitted beneficiary', _beneficiary); 
            const beneficiaryService = new BeneficiaryService();
            let _beneficiaries = [...beneficiaries];
            if (!beneficiary.id) {
                beneficiaryService.create(_beneficiary).then(data => {
                    console.log('data', data);
                    _beneficiaries.push(data);
                    setBeneficiaries(_beneficiaries);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Création réussie!', life: 3000 });
                }).catch(error => {
                    console.log(error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Enregistrement échoué', life: 3000 });
                });
            }
            else {
                beneficiaryService.update(beneficiary.id, _beneficiary).then(data => {
                    console.log('data', data);
                    const index = findIndexById(beneficiary.id);
                    _beneficiaries[index] = _beneficiary;
                    setBeneficiaries(_beneficiaries);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Modification réussie!', life: 3000 });
                }).catch(error => {
                    console.log(error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Enregistrement échoué', life: 3000 });
                });
            }
            setBeneficiaryDialog(false);
            setBeneficiary(emptyBeneficiary);
        }
    }

    const editBeneficiary = (beneficiary) => {
        setBeneficiary({ 
            ...beneficiary, 
            birthday: new Date(beneficiary.birthday),
            selectedType: beneficiary.beneficiary_type,
            selectedAdhering: beneficiary.adhering_structure
        });
        setBeneficiaryDialog(true);
    }

    const confirmDeleteBeneficiary = (beneficiary) => {
        setBeneficiary({ ...beneficiary });
        setDeleteBeneficiaryDialog(true);
    }

    const deleteBeneficiary = () => {
        const beneficiaryService = new BeneficiaryService();
        beneficiaryService.delete(beneficiary.id).then(data => {
            console.log('data', data);
            let _beneficiaries = beneficiaries.filter(val => val.id !== beneficiary.id);
            setBeneficiaries(_beneficiaries);          
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Sppression réussie!', life: 3000 });
        }).catch(error => {
            console.log(error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Supression échoué', life: 3000 });
        });
        setDeleteBeneficiaryDialog(false);
        setBeneficiary(emptyBeneficiary); 
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < beneficiaries.length; i++) {
            if (beneficiaries[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteBeneficiarysDialog(true);
    }

    const deleteSelectedBeneficiaries = () => {
        let _beneficiaries = beneficiaries.filter(val => !selectedBeneficiaries.includes(val));
        setBeneficiaries(_beneficiaries);
        setDeleteBeneficiarysDialog(false);
        setSelectedBeneficiaries(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'beneficiaries Deleted', life: 3000 });
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _beneficiary = { ...beneficiary };
        _beneficiary[`${name}`] = val;
        setBeneficiary(_beneficiary);
    }

    const onSexChange = (e) => {
        let _beneficiary = { ...beneficiary };
        _beneficiary['sex'] = e.value;
        setBeneficiary(_beneficiary);
    }

    const onBirthdayChange = (e) => {
        let _beneficiary = { ...beneficiary };
        _beneficiary['birthday'] = e.value;
        setBeneficiary(_beneficiary);
    }

    const onAdheringChange = (e) => {
        setBeneficiary({...beneficiary, selectedAdhering: e.value});
        console.log('selected adhering', e.value);
    }

    const onBeneficiaryTypeChange = (e) => {
        setBeneficiary({...beneficiary, selectedType: e.value});
        console.log('selected type', e.value);
    }

    const adheringOptionTemplate = (option) => {
        return (
            <div className="">
                <span className="mx-1">{option.name}</span>
                <span className="mx-1">{option.phone}</span>
            </div>
        );
    }

    const beneficiary_typeOptionTemplate = (option) => {
        return (
            <div className="">
                <div>{option.label}</div>
            </div>
        );
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedBeneficiaries || !selectedBeneficiaries.length} />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const avatarBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Avatar</span>
                <img src={`${rowData.avatar}`} alt='avatar' className="shadow-2" width="100" />
            </>
        )
    }

    const first_nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Prénom</span>
                {rowData.first_name}
            </>
        );
    }

    const last_nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nom</span>
                {rowData.last_name}
            </>
        );
    }

    const sexBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Sexe</span>
                {getSexLabel(rowData.sex)}
            </>
        );
    }

    const addressBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Adresse</span>
                {rowData.address}
            </>
        );
    }

    const phoneBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Téléphone</span>
                {rowData.phone}
            </>
        );
    }

    const beneficiary_typeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Type</span>
                {rowData.beneficiary_type}
            </>
        );
    }

    const birthdayBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date de Naissance</span>
                {rowData.birthday}
            </>
        );
    }

    const birth_placeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Lieu de naissance</span>
                {rowData.birth_place}
            </>
        );
    }

    const adheringBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Adhérant</span>
                {rowData.adhering_structure?.name || ''}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editBeneficiary(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteBeneficiary(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Gestion des ayants droits</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const beneficiaryDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveBeneficiary} />
        </>
    );
    const deleteBeneficiaryDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteBeneficiaryDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteBeneficiary} />
        </>
    );
    const deleteBeneficiarysDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteBeneficiarysDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedBeneficiaries} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={beneficiaries} selection={selectedBeneficiaries} onSelectionChange={(e) => setSelectedBeneficiaries(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} beneficiaries"
                        globalFilter={globalFilter} emptyMessage="No beneficiaries found." header={header} responsiveLayout="scroll">
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column header="AVATAR" body={avatarBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="firts_name" header="PRENOM" sortable body={first_nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="last_name" header="NOM" sortable body={last_nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="sex" header="SEXE" body={sexBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="address" header="ADRESSE" sortable body={addressBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="phone" header="TELEPHONE" sortable body={phoneBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="beneficiary_type" header="TYPE AYANT DROIT" sortable body={beneficiary_typeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="birthday" header="DATE DE NAISSANCE" body={birthdayBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="birth_place" header="LIEU DE NAISSANCE" sortable body={birth_placeBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="adhering_structure_id" header="ADHERANT" sortable body={adheringBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={beneficiaryDialog} style={{ width: '450px' }} header="Product Details" modal className="p-fluid" footer={beneficiaryDialogFooter} onHide={hideDialog}>
                        {beneficiary.image && <img src={`assets/demo/images/product/${beneficiary.image}`} alt={beneficiary.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}

                        {/* firts_name && last_name */}
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="first_name">Prénom</label>
                                <InputText id="first_name" value={beneficiary.first_name} onChange={(e) => onInputChange(e, 'first_name')}
                                 required autoFocus className={classNames({ 'p-invalid': submitted && !beneficiary.first_name })} />
                                {submitted && !beneficiary.first_name && <small className="p-invalid">Firstname is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="last_name">Nom</label>
                                <InputText id="last_name" value={beneficiary.last_name} onChange={(e) => onInputChange(e, 'last_name')} 
                                 required autoFocus className={classNames({ 'p-invalid': submitted && !beneficiary.last_name })} />
                                {submitted && !beneficiary.last_name && <small className="p-invalid">Lastname is required.</small>}
                            </div>
                        </div>

                        {/* sex && phone */}
                        <div className="formgrid grid">
                            <div className="field col">
                                <label>Sexe</label>
                                <div className="formgrid grid">
                                    <div className="field-radiobutton col">
                                        <RadioButton inputId="sex1" name="sex" value="male" onChange={onSexChange} checked={beneficiary.sex === 'male'} />
                                        <label htmlFor="sex1">Homme</label>
                                    </div>
                                    <div className="field-radiobutton col">
                                        <RadioButton inputId="sex2" name="sex" value="female" onChange={onSexChange} checked={beneficiary.sex === 'female'} />
                                        <label htmlFor="sex2">Femme</label>
                                    </div>
                                </div>
                            </div>
                            <div className="field col">
                                <label htmlFor="phone">Télépone</label>
                                <InputText id="phone" value={beneficiary.phone} onChange={(e) => onInputChange(e, 'phone')}
                                 required autoFocus className={classNames({ 'p-invalid': submitted && !beneficiary.phone })} />
                                {submitted && !beneficiary.phone && <small className="p-invalid">Phone is required.</small>}
                            </div>
                        </div>

                        {/* address */}
                        <div className="field">
                            <label htmlFor="name">Adresse</label>
                            <InputText id="address" value={beneficiary.address} onChange={(e) => onInputChange(e, 'address')}
                             required autoFocus className={classNames({ 'p-invalid': submitted && !beneficiary.address })} />
                            {submitted && !beneficiary.address && <small className="p-invalid">Address is required.</small>}
                        </div>

                        {/* sex && birthday */}
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="birthday">Date de naissance</label>
                                <Calendar value={beneficiary.birthday} onChange={onBirthdayChange} 
                                viewDate={viewDate} onViewDateChange={(e) => setViewDate(e.value)}
                                required autoFocus className={classNames({ 'p-invalid': submitted && !beneficiary.birthday })}></Calendar> 
                                {submitted && !beneficiary.birthday && <small className="p-invalid">Birthday is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="birth_place">Lieu de naissance</label>
                                <InputText id="birth_place" value={beneficiary.birth_place} onChange={(e) => onInputChange(e, 'birth_place')}
                                 required autoFocus className={classNames({ 'p-invalid': submitted && !beneficiary.birth_place })} />
                                {submitted && !beneficiary.birth_place && <small className="p-invalid">Birth place is required.</small>}
                            </div>
                        </div>

                        {/* adhering & beneficiary_type */}
                        <div className="field">
                            <div className="formgrid grid">
                                <div className="field col-6">
                                    <label htmlFor="name">Adhérant</label>
                                    <Dropdown value={beneficiary.selectedAdhering} options={adherings} onChange={onAdheringChange} 
                                        optionLabel="name" filter showClear filterBy="name"
                                        placeholder="Selectionner une adhérant" itemTemplate={adheringOptionTemplate}
                                        required autoFocus className={classNames({ 'p-invalid': submitted && !beneficiary.selectedAdhering })}/>
                                        {submitted && !beneficiary.selectedAdhering && <small className="p-invalid">Adhering is required.</small>}
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="beneficiary_type">Type ayant droit</label>
                                    <Dropdown value={beneficiary.selectedType} options={beneficiaryTypes} onChange={onBeneficiaryTypeChange} 
                                        placeholder="Selectionner un type ayant droit" itemTemplate={beneficiary_typeOptionTemplate}
                                        required autoFocus className={classNames({ 'p-invalid': submitted && !beneficiary.selectedType })}/>
                                        {submitted && !beneficiary.selectedType && <small className="p-invalid">Type is required.</small>}
                                </div>
                            </div>
                        </div>

                    </Dialog>

                    <Dialog visible={deleteBeneficiaryDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteBeneficiaryDialogFooter} onHide={hideDeleteBeneficiaryDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {beneficiary && <span>Are you sure you want to delete <b>{beneficiary.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteBeneficiarysDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteBeneficiarysDialogFooter} onHide={hideDeleteBeneficiarysDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {beneficiary && <span>Are you sure you want to delete the selected beneficiaries?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(BeneficiaryPage, comparisonFn);
