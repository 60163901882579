import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { RadioButton } from 'primereact/radiobutton';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
//myImports
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import UserService from '../../service/sessions/User.service';
import getEnv from '../../common/env';
import RoleService from '../../service/sessions/Role.service';
import IpmService from '../../service/cruds/IpmService';
import utils from '../../common/utils';
import PharmacyService from '../../service/cruds/PharmacyService';
import HospitalService from '../../service/cruds/HospitalService';
import AdheringSructureService from '../../service/cruds/AdheringSructureService';

const UserPage = () => {

    let emptyUser = {
        id: null,
        avatar: null,
        first_name: '',
        last_name: '',
        sex: 'male',
        birthday: null,
        email: '',
        address: '',
        phone: '',
        cni: '',
        roles: [],
        pharmacy_id: null,
        hospital_id: null,
        ipm_id: null,
        adhering_structure_id: null,
        checkingRoles: [],
        selectedPharmacy: null,
        selectedHospital: null,
        selectedIpm: null,
        selectedAdhering_structure: null,
    };

    const [users, setUsers] = useState(null);
    const [userDialog, setUserDialog] = useState(false);
    const [deleteUserDialog, setDeleteUserDialog] = useState(false);
    const [deleteUsersDialog, setDeleteUsersDialog] = useState(false);
    const [user, setUser] = useState(emptyUser);
    const [selectedUsers, setSelectedUsers] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    //my attrs
    const [viewDate, setViewDate] = useState(null);
    const [roles, setRoles] = useState([]);
    const [pharmacys, setPharmacies] = useState([]);
    const [hospitals, setHospitals] = useState([]);
    const [ipms, setIpms] = useState([]);
    const [adheringStructures, setAdheringStructures] = useState([]);
    const roleNames = {
        pharmacy: 'Pharmacist',
        hospital: 'Hospital manager',
        ipm: 'Ipm manager',
        adheringStructure: 'Adhering structure manager',
    }

    const getRoleIdByName = (name) => {
        return roles.find(r => name === r.name)?.id;
    }
    
    useEffect(() => {
        const userService = new UserService();
        const roleService = new RoleService();
        const pharmacyService = new PharmacyService();
        const hospitalService = new HospitalService();
        const ipmService = new IpmService();
        const adheringSructureService = new AdheringSructureService();
        roleService.getAssinginsRoles().then(data => {
            setRoles(data);
            //console.log('roles data', data);
        });
        userService.list().then(data => {
            setUsers(data);
            console.log('users data', data);
        });
        pharmacyService.list().then(data => {
            setPharmacies(data);
            //console.log('pharmacies data', data);
        });
        hospitalService.list().then(data => {
            setHospitals(data);
            //console.log('hospitals data', data);
        });
        ipmService.list().then(data => {
            setIpms(data);
            //console.log('ipms data', data);
        });
        adheringSructureService.list().then(data => {
            setAdheringStructures(data);
            //console.log('adheringSructures data', data);
        });
    }, []);

    const getSexLabel = (value) => {
        if (value === 'male') {
            return 'Homme';
        } else {
            return 'Femme';
        }
    }

    const getRoleLabel = (roleName) => {
        if (roleName === roleNames.pharmacy) {
            return 'Gérant Pharmacie';
        }
        else if (roleName === roleNames.hospital) {
            return 'Gérant Hopital';
        }
        else if (roleName === roleNames.ipm) {
            return 'Gérant Ipm';
        }
        else if (roleName === roleNames.adheringStructure) {
            return 'Gérant Adhérant';
        }
        else {
            return 'Role Inconnu';
        }
    }

    const openNew = () => {
        setUser(emptyUser);
        setSubmitted(false);
        setUserDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setUserDialog(false);
    }

    const hideDeleteUserDialog = () => {
        setDeleteUserDialog(false);
    }

    const hideDeleteUsersDialog = () => {
        setDeleteUsersDialog(false);
    }

    const formIsValid = () => {
        return Boolean(user.last_name.trim());
    }

    const saveUser = () => {
        setSubmitted(true);
        if (formIsValid()) {
            //console.log('checkingRoles', user.checkingRoles);
            //console.log('selectedIpm', user.selectedIpm);
            let _user = { ...user, birthday: user.birthday.toISOString().split('T')[0] };
            _user.roles = JSON.stringify(user.checkingRoles);
            //_user.ipm = _user.selectedIpm;  //uneccessary because ipm_id is used for save
            _user.ipm_id = _user.selectedIpm?.id;
            delete _user.avatar;
            console.log('submitted user', _user); 
            //return null;
            const userService = new UserService();
            let _users = [...users];
            if (!user.id) {
                userService.create(_user).then(data => {
                    console.log('data', data);
                    _users.push(data);
                    setUsers(_users);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Création réussie!', life: 3000 });
                }).catch(error => {
                    console.log(error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Enregistrement échoué', life: 3000 });
                });
            }
            else {
                userService.update(user.id, _user).then(data => {
                    console.log('data', data);
                    const index = findIndexById(user.id);
                    _users[index] = _user;
                    setUsers(_users);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Modification réussie!', life: 3000 });
                }).catch(error => {
                    console.log(error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Enregistrement échoué', life: 3000 });
                });
            }
            setUserDialog(false);
            setUser(emptyUser);
        }
    }

    const editUser = (user) => {
        setUser({ ...user, 
            birthday: new Date(user.birthday),
            checkingRoles: user.roles,
            selectedIpm: user.ipm
        });
        setUserDialog(true);
    }

    const confirmDeleteUser = (user) => {
        setUser({ ...user, 
            checkingRoles: user.roles,
        });
        //setUser(user);
        setDeleteUserDialog(true);
    }

    const deleteUser = () => {
        const userService = new UserService();
        userService.delete(user.id).then(data => {
            console.log('data', data);
            let _users = users.filter(val => val.id !== user.id);
            setUsers(_users);          
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Sppression réussie!', life: 3000 });
        }).catch(error => {
            console.log(error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Supression échoué', life: 3000 });
        });
        setDeleteUserDialog(false);
        setUser(emptyUser); 
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < users.length; i++) {
            if (users[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteUsersDialog(true);
    }

    const deleteSelectedUsers = () => {
        let _users = users.filter(val => !selectedUsers.includes(val));
        setUsers(_users);
        setDeleteUsersDialog(false);
        setSelectedUsers(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'users Deleted', life: 3000 });
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _user = { ...user };
        _user[`${name}`] = val;
        setUser(_user);
    }

    const onSexChange = (e) => {
        let _user = { ...user };
        _user['sex'] = e.value;
        setUser(_user);
    }

    const onBirthdayChange = (e) => {
        let _user = { ...user };
        _user['birthday'] = e.value;
        setUser(_user);
    }

    const onRoleChange = (e) => {
        console.log('selected role checked', e.checked, e.value); //return null;
        let _user = {...user};
        let selectedRoles = [...user.checkingRoles];
        if(e.checked)
            selectedRoles.push(e.value);
        else
            selectedRoles.splice(selectedRoles.indexOf(e.value), 1);
            //init Selects
            if (e.value === getRoleIdByName(roleNames.pharmacy)){_user.selectedPharmacy = null}
            if (e.value === getRoleIdByName(roleNames.hospital)){_user.selectedHospital = null}
            if (e.value === getRoleIdByName(roleNames.ipm)){_user.selectedIpm = null}
            if (e.value === getRoleIdByName(roleNames.adheringStructure)){_user.selectedAdhering_structure = null}
            //end init Selects
        _user.checkingRoles = selectedRoles;
        setUser(_user);
        //initRoleSelect(e.value, e.checked); //init selects
    }

    const onPharmacyChange = (e) => {
        setUser({...user, selectedPharmacy: e.value});
        //console.log('selected pharmacy', e.value);
    }

    const onHospitalChange = (e) => {
        setUser({...user, selectedHospital: e.value});
        //console.log('selected hospital', e.value);
    }

    const onIpmChange = (e) => {
        setUser({...user, selectedIpm: e.value});
        //console.log('selected ipm', e.value);
    }

    const onAdheringStructureChange = (e) => {
        setUser({...user, selectedAdhering_structure: e.value});
        //console.log('selected adhering structure', e.value);
    }

    const pharmacyOptionTemplate = (option) => {
        return (
            <div className="">
                <div>{option.name}</div>
            </div>
        );
    }

    const hospitalOptionTemplate = (option) => {
        return (
            <div className="">
                <div>{option.name}</div>
            </div>
        );
    }

    const ipmOptionTemplate = (option) => {
        return (
            <div className="">
                {/* <img alt={option.name} src="images/flag/flag_placeholder.png" className={`flag flag-${option.code.toLowerCase()}`} /> */}
                <div>{option.name}</div>
            </div>
        );
    }

    const adheringStructureOptionTemplate = (option) => {
        return (
            <div className="">
                <div>{option.name}</div>
            </div>
        );
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedUsers || !selectedUsers.length} />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const avatarBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Avatar</span>
                <img src={`${getEnv().baseHostUrl}${rowData.avatar}`} alt='avatar' className="shadow-2" width="100" />
            </>
        )
    }

    const first_nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Prénom</span>
                {rowData.first_name}
            </>
        );
    }

    const last_nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nom</span>
                {rowData.last_name}
            </>
        );
    }

    const sexBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Sexe</span>
                {getSexLabel(rowData.sex)}
            </>
        );
    }

    const birthdayBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Date de Naissance</span>
                {rowData.birthday}
            </>
        );
    }

    const emailBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Email</span>
                {rowData.email}
            </>
        );
    }

    const addressBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Adresse</span>
                {rowData.address}
            </>
        );
    }

    const phoneBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Téléphone</span>
                {rowData.phone}
            </>
        );
    }

    const cniBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Cni</span>
                {rowData.cni}
            </>
        );
    }

    const rolesBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Role</span>
                {getRoleLabel(rowData.role_names[0])}
            </>
        );
    }

    const ipmBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Ipm</span>
                {rowData.ipm?.name || ''}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editUser(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteUser(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Gestion des utilisateurs</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const userDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveUser} />
        </>
    );
    const deleteUserDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUserDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteUser} />
        </>
    );
    const deleteUsersDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUsersDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedUsers} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={users} selection={selectedUsers} onSelectionChange={(e) => setSelectedUsers(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                        globalFilter={globalFilter} emptyMessage="No users found." header={header} responsiveLayout="scroll">
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column header="Avatar" body={avatarBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="firts_name" header="Prénom" sortable body={first_nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="last_name" header="Nom" sortable body={last_nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="sex" header="Sexe" body={sexBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="birthday" header="Date de naissance" body={birthdayBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="email" header="Email" sortable body={emailBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="address" header="Adresse" sortable body={addressBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="phone" header="Téléphone" sortable body={phoneBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="cni" header="Cni" sortable body={cniBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column header="Role" sortable body={rolesBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="ipm_id" header="Ipm" sortable body={ipmBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={userDialog} style={{ width: '450px' }} header="Product Details" modal className="p-fluid" footer={userDialogFooter} onHide={hideDialog}>
                        {user.image && <img src={`assets/demo/images/product/${user.image}`} alt={user.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}

                        {/* firts_name && last_name */}
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="first_name">Prénom</label>
                                <InputText id="first_name" value={user.first_name} onChange={(e) => onInputChange(e, 'first_name')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.first_name })} />
                                {submitted && !user.first_name && <small className="p-invalid">Firstname is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="last_name">Nom</label>
                                <InputText id="last_name" value={user.last_name} onChange={(e) => onInputChange(e, 'last_name')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.last_name })} />
                                {submitted && !user.last_name && <small className="p-invalid">Lastname is required.</small>}
                            </div>
                        </div>

                        {/* sex && birthday */}
                        <div className="formgrid grid">
                            <div className="field col">
                                <label>Sexe</label>
                                <div className="formgrid grid">
                                    <div className="field-radiobutton col">
                                        <RadioButton inputId="sex1" name="sex" value="male" onChange={onSexChange} checked={user.sex === 'male'} />
                                        <label htmlFor="sex1">Homme</label>
                                    </div>
                                    <div className="field-radiobutton col">
                                        <RadioButton inputId="sex2" name="sex" value="female" onChange={onSexChange} checked={user.sex === 'female'} />
                                        <label htmlFor="sex2">Femme</label>
                                    </div>
                                </div>
                            </div>
                            <div className="field col">
                                <label htmlFor="birthday">Date de naissance</label>
                                <Calendar value={user.birthday} onChange={onBirthdayChange} 
                                viewDate={viewDate} onViewDateChange={(e) => setViewDate(e.value)}
                                required autoFocus className={classNames({ 'p-invalid': submitted && !user.birthday })}></Calendar> 
                                {submitted && !user.birthday && <small className="p-invalid">Birthday is required.</small>}
                            </div>
                        </div>

                        {/* email && phone */}
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="email">Email</label>
                                <InputText id="email" value={user.email} onChange={(e) => onInputChange(e, 'email')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.email })} />
                                {submitted && !user.email && <small className="p-invalid">Email is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="phone">Télépone</label>
                                <InputText id="phone" value={user.phone} onChange={(e) => onInputChange(e, 'phone')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.phone })} />
                                {submitted && !user.phone && <small className="p-invalid">Phone is required.</small>}
                            </div>
                        </div>

                        {/* address */}
                        <div className="field">
                            <label htmlFor="name">Adresse</label>
                            <InputText id="address" value={user.address} onChange={(e) => onInputChange(e, 'address')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.address })} />
                            {submitted && !user.address && <small className="p-invalid">Address is required.</small>}
                        </div>

                        {/* cni */}
                        <div className="field col-8">
                            <label htmlFor="name">Cni</label>
                            <InputText id="cni" value={user.cni} onChange={(e) => onInputChange(e, 'cni')} required autoFocus className={classNames({ 'p-invalid': submitted && !user.cni })} />
                            {submitted && !user.cni && <small className="p-invalid">Cni is required.</small>}
                        </div>

                        {/* roles */}
                        <div className="field">
                            <label className="mb-3">Roles</label>
                            <div className="formgrid grid">
                                {roles.map((role) => {
                                    return (
                                        <div className="col-6">
                                            <Checkbox key={`${role.id}-${utils.createId()}`} inputId={`cb-${role.id}`} value={role.id} onChange={onRoleChange} 
                                            checked={user.checkingRoles.includes(role.id)}/>
                                            <label htmlFor={`cb-${role.id}`} className="p-checkbox-label">{getRoleLabel(role.name)}</label>
                                        </div>                                            
                                    );
                                })}
                            </div>
                        </div>

                        {/* parents */}
                        <div className="field">
                            {/* <label className="mt-3">Structures</label> */}
                            <div className="formgrid grid">
                                <div className="field col-6">
                                    <label htmlFor="name">Pharmacie</label>
                                    <Dropdown value={user.selectedPharmacy} options={pharmacys} onChange={onPharmacyChange} 
                                        optionLabel="name" filter showClear filterBy="name"
                                        placeholder="Selectionner une pharmacie" itemTemplate={pharmacyOptionTemplate} 
                                        disabled={!user.checkingRoles.includes(getRoleIdByName(roleNames.pharmacy))}/>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="name">Structure de santé</label>
                                    <Dropdown value={user.selectedHospital} options={hospitals} onChange={onHospitalChange} 
                                        optionLabel="name" filter showClear filterBy="name"
                                        placeholder="Selectionner une structure de santé" itemTemplate={hospitalOptionTemplate} 
                                        disabled={!user.checkingRoles.includes(getRoleIdByName(roleNames.hospital))}/>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="name">Ipm</label>
                                    <Dropdown value={user.selectedIpm} options={ipms} onChange={onIpmChange} 
                                        optionLabel="name" filter showClear filterBy="name"
                                        placeholder="Selectionner un ipm" itemTemplate={ipmOptionTemplate} 
                                        disabled={!user.checkingRoles.includes(getRoleIdByName(roleNames.ipm))}/>
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="name">Adhérant</label>
                                    <Dropdown value={user.selectedAdhering_structure} options={adheringStructures} onChange={onAdheringStructureChange} 
                                        optionLabel="name" filter showClear filterBy="name"
                                        placeholder="Selectionner un ipm" itemTemplate={adheringStructureOptionTemplate} 
                                        disabled={!user.checkingRoles.includes(getRoleIdByName(roleNames.adheringStructure))}/>
                                </div>
                            </div>
                        </div>

                    </Dialog>

                    <Dialog visible={deleteUserDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteUserDialogFooter} onHide={hideDeleteUserDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {user && <span>Are you sure you want to delete <b>{user.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteUsersDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteUsersDialogFooter} onHide={hideDeleteUsersDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {user && <span>Are you sure you want to delete the selected users?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(UserPage, comparisonFn);
