import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom'
import AppWrapper from './AppWrapper';
//import * as serviceWorker from './serviceWorker';
//my imports
import axios from 'axios';
import authService from './service/sessions/auth.service';

//requests
axios.interceptors.request.use(request => {
	// add auth header with jwt if account is logged in and request is to the api url
	//console.log('intercepting: ', request.url);
	const authToken = authService.getToken();
	const isAllowUrl = request.url.endsWith('login/');
	if (!isAllowUrl && Boolean(authToken)) {
		//console.log(': ', `add [Autorization: Token ${authToken}]`);
		request.headers.common.Authorization = `Token ${authToken}`;
	}
	return request;
});

// For POST requests
axios.interceptors.response.use(
	(res) => {
	   // Add configurations here
	   if (res.status === 201) {
		  console.log('Posted Successfully');
	   }
	   return res;
	},
	(err) => {
	   return Promise.reject(err);
	}
 );

ReactDOM.render(
	<HashRouter>
		<AppWrapper></AppWrapper>
	</HashRouter>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
