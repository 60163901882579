import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { RadioButton } from 'primereact/radiobutton';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
//myImports
import { Dropdown } from 'primereact/dropdown';
//import getEnv from '../../common/env';
import HospitalService from '../../service/cruds/HospitalService';
import DoctorService from '../../service/cruds/DoctorService';

const DoctorPage = () => {

    let emptyDoctor = {
        id: null,
        avatar: null,
        first_name: '',
        last_name: '',
        sex: 'male',
        address: '',
        phone: '',
        speciality: '',
        hospital_id: null,
        selectedHospital: null,
    };

    const [doctors, setDoctors] = useState(null);
    const [doctorDialog, setDoctorDialog] = useState(false);
    const [deleteDoctorDialog, setDeleteDoctorDialog] = useState(false);
    const [deleteDoctorsDialog, setDeleteDoctorsDialog] = useState(false);
    const [doctor, setDoctor] = useState(emptyDoctor);
    const [selectedDoctors, setSelectedDoctors] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    //my attrs
    const [hospitals, setHospitals] = useState([]);
    
    useEffect(() => {
        const doctorService = new DoctorService();
        const hospitalService = new HospitalService();
        doctorService.list().then(data => {
            setDoctors(data);
            console.log('doctors data', data);
        });
        hospitalService.list().then(data => {
            setHospitals(data);
            console.log('hospitals data', data);
        });
    }, []);

    const getSexLabel = (value) => {
        if (value === 'male') {
            return 'Homme';
        } else {
            return 'Femme';
        }
    }

    const openNew = () => {
        setDoctor(emptyDoctor);
        setSubmitted(false);
        setDoctorDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setDoctorDialog(false);
    }

    const hideDeleteDoctorDialog = () => {
        setDeleteDoctorDialog(false);
    }

    const hideDeleteDoctorsDialog = () => {
        setDeleteDoctorsDialog(false);
    }

    const formIsValid = () => {
        return Boolean(doctor.last_name.trim());
    }

    const saveDoctor = () => {
        setSubmitted(true);
        if (formIsValid()){
            //console.log('selectedHospital', doctor.selectedHospital);
            let _doctor = { ...doctor };
            _doctor.hospital_id = _doctor.selectedHospital?.id;
            delete _doctor.avatar;
            //console.log('submitted doctor', _doctor); 
            const doctorService = new DoctorService();
            let _doctors = [...doctors];
            if (!doctor.id) {
                doctorService.create(_doctor).then(data => {
                    console.log('data', data);
                    _doctors.push(data);
                    setDoctors(_doctors);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Création réussie!', life: 3000 });
                }).catch(error => {
                    console.log(error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Enregistrement échoué', life: 3000 });
                });
            }
            else {
                doctorService.update(doctor.id, _doctor).then(data => {
                    console.log('data', data);
                    const index = findIndexById(doctor.id);
                    _doctors[index] = _doctor;
                    setDoctors(_doctors);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Modification réussie!', life: 3000 });
                }).catch(error => {
                    console.log(error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Enregistrement échoué', life: 3000 });
                });
            }
            setDoctorDialog(false);
            setDoctor(emptyDoctor);
        }
    }

    const editDoctor = (doctor) => {
        setDoctor({ ...doctor, selectedHospital: doctor.hospital });
        setDoctorDialog(true);
    }

    const confirmDeleteDoctor = (doctor) => {
        setDoctor({ ...doctor });
        setDeleteDoctorDialog(true);
    }

    const deleteDoctor = () => {
        const doctorService = new DoctorService();
        doctorService.delete(doctor.id).then(data => {
            console.log('data', data);
            let _doctors = doctors.filter(val => val.id !== doctor.id);
            setDoctors(_doctors);          
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Sppression réussie!', life: 3000 });
        }).catch(error => {
            console.log(error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Supression échoué', life: 3000 });
        });
        setDeleteDoctorDialog(false);
        setDoctor(emptyDoctor); 
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < doctors.length; i++) {
            if (doctors[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteDoctorsDialog(true);
    }

    const deleteSelectedDoctors = () => {
        let _doctors = doctors.filter(val => !selectedDoctors.includes(val));
        setDoctors(_doctors);
        setDeleteDoctorsDialog(false);
        setSelectedDoctors(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'doctors Deleted', life: 3000 });
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _doctor = { ...doctor };
        _doctor[`${name}`] = val;
        setDoctor(_doctor);
    }

    const onSexChange = (e) => {
        let _doctor = { ...doctor };
        _doctor['sex'] = e.value;
        setDoctor(_doctor);
    }

    const onHospitalChange = (e) => {
        setDoctor({...doctor, selectedHospital: e.value});
        //console.log('selected hospital', e.value);
    }

    const hospitalOptionTemplate = (option) => {
        return (
            <div className="">
                <div>{option.name}</div>
            </div>
        );
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedDoctors || !selectedDoctors.length} />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const avatarBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Avatar</span>
                <img src={`${rowData.avatar}`} alt='avatar' className="shadow-2" width="100" />
            </>
        )
    }

    const first_nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Prénom</span>
                {rowData.first_name}
            </>
        );
    }

    const last_nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nom</span>
                {rowData.last_name}
            </>
        );
    }

    const sexBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Sexe</span>
                {getSexLabel(rowData.sex)}
            </>
        );
    }

    const addressBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Adresse</span>
                {rowData.address}
            </>
        );
    }

    const phoneBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Téléphone</span>
                {rowData.phone}
            </>
        );
    }

    const specialityBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Spécialité</span>
                {rowData.speciality}
            </>
        );
    }

    const hospitalBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Structure de santé</span>
                {rowData.hospital?.name || ''}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editDoctor(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteDoctor(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Gestion des médecins</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const doctorDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveDoctor} />
        </>
    );
    const deleteDoctorDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDoctorDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteDoctor} />
        </>
    );
    const deleteDoctorsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDoctorsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedDoctors} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={doctors} selection={selectedDoctors} onSelectionChange={(e) => setSelectedDoctors(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} doctors"
                        globalFilter={globalFilter} emptyMessage="No doctors found." header={header} responsiveLayout="scroll">
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column header="AVATAR" body={avatarBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="firts_name" header="PRENOM" sortable body={first_nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="last_name" header="NOM" sortable body={last_nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="sex" header="SEXE" body={sexBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="address" header="ADRESSE" sortable body={addressBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="phone" header="TELEPHONE" sortable body={phoneBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="speciality" header="SPECIALITE" sortable body={specialityBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column field="hospital_id" header="STRUCTURE DE SANTE" sortable body={hospitalBodyTemplate} headerStyle={{ width: '14%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={doctorDialog} style={{ width: '450px' }} header="Product Details" modal className="p-fluid" footer={doctorDialogFooter} onHide={hideDialog}>
                        {doctor.image && <img src={`assets/demo/images/product/${doctor.image}`} alt={doctor.image} width="150" className="mt-0 mx-auto mb-5 block shadow-2" />}

                        {/* firts_name && last_name */}
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="first_name">Prénom</label>
                                <InputText id="first_name" value={doctor.first_name} onChange={(e) => onInputChange(e, 'first_name')}
                                 required autoFocus className={classNames({ 'p-invalid': submitted && !doctor.first_name })} />
                                {submitted && !doctor.first_name && <small className="p-invalid">Firstname is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="last_name">Nom</label>
                                <InputText id="last_name" value={doctor.last_name} onChange={(e) => onInputChange(e, 'last_name')} 
                                 required autoFocus className={classNames({ 'p-invalid': submitted && !doctor.last_name })} />
                                {submitted && !doctor.last_name && <small className="p-invalid">Lastname is required.</small>}
                            </div>
                        </div>

                        {/* sex && phone */}
                        <div className="formgrid grid">
                            <div className="field col">
                                <label>Sexe</label>
                                <div className="formgrid grid">
                                    <div className="field-radiobutton col">
                                        <RadioButton inputId="sex1" name="sex" value="male" onChange={onSexChange} checked={doctor.sex === 'male'} />
                                        <label htmlFor="sex1">Homme</label>
                                    </div>
                                    <div className="field-radiobutton col">
                                        <RadioButton inputId="sex2" name="sex" value="female" onChange={onSexChange} checked={doctor.sex === 'female'} />
                                        <label htmlFor="sex2">Femme</label>
                                    </div>
                                </div>
                            </div>
                            <div className="field col">
                                <label htmlFor="phone">Télépone</label>
                                <InputText id="phone" value={doctor.phone} onChange={(e) => onInputChange(e, 'phone')}
                                 required autoFocus className={classNames({ 'p-invalid': submitted && !doctor.phone })} />
                                {submitted && !doctor.phone && <small className="p-invalid">Phone is required.</small>}
                            </div>
                        </div>

                        {/* address */}
                        <div className="field">
                            <label htmlFor="name">Adresse</label>
                            <InputText id="address" value={doctor.address} onChange={(e) => onInputChange(e, 'address')}
                             required autoFocus className={classNames({ 'p-invalid': submitted && !doctor.address })} />
                            {submitted && !doctor.address && <small className="p-invalid">Address is required.</small>}
                        </div>

                        {/* hospital & speciality */}
                        <div className="field">
                            <div className="formgrid grid">
                                <div className="field col-6">
                                    <label htmlFor="name">Structure de santé</label>
                                    <Dropdown value={doctor.selectedHospital} options={hospitals} onChange={onHospitalChange} 
                                        optionLabel="name" filter showClear filterBy="name"
                                        placeholder="Selectionner une structure de santé" itemTemplate={hospitalOptionTemplate}
                                        required autoFocus className={classNames({ 'p-invalid': submitted && !doctor.selectedHospital })}/>
                                        {submitted && !doctor.selectedHospital && <small className="p-invalid">Speciality is required.</small>}
                                </div>
                                <div className="field col-6">
                                    <label htmlFor="speciality">Spécialité</label>
                                    <InputText id="speciality" value={doctor.speciality} onChange={(e) => onInputChange(e, 'speciality')}
                                     required autoFocus className={classNames({ 'p-invalid': submitted && !doctor.speciality })} />
                                    {submitted && !doctor.speciality && <small className="p-invalid">Speciality is required.</small>}
                                </div>
                            </div>
                        </div>

                    </Dialog>

                    <Dialog visible={deleteDoctorDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDoctorDialogFooter} onHide={hideDeleteDoctorDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {doctor && <span>Are you sure you want to delete <b>{doctor.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteDoctorsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteDoctorsDialogFooter} onHide={hideDeleteDoctorsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {doctor && <span>Are you sure you want to delete the selected doctors?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(DoctorPage, comparisonFn);
