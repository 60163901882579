import axios from "axios";
import getEnv from "../../common/env";
import storageService from "./storage.service";

const API_URL = getEnv().baseUrl + "/accounts/";

class AuthService {
  login(credentials) {
    return axios
      .post(API_URL + "login/", credentials)
      .then(response => {
        //console.log('response', response)
        if (response.data.success) {
          //localStorage.setItem("user", JSON.stringify(response.data));
          const auth_data = response.data.object;
          //console.log('auth_data', auth_data)
          storageService.set("token", auth_data.token);
          storageService.set("user", auth_data.user);
        }
        return response.data;
      });
  }

  logout() {
    return axios
      .get(API_URL + "logout/")
      .then(response => {
        //console.log('response', response)
        return response.data;
      });
  }

  register(username, email, password) {
    return axios.post(API_URL + "signup", {
      username,
      email,
      password
    });
  }

  getToken() {
    return storageService.get('token')
  }

  getCurrentUser() {
    return storageService.get('user')
  }

  clearSession(){
    storageService.clear();
  }

}

export default new AuthService();
