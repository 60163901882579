import React, { useState, useEffect, useRef } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
//my imports
import IpmService from '../../service/cruds/IpmService';

const IpmPage = () => {

    let emptyIpm = {
        id: null,
        name: '',
        phone: '',
        address: '',
    };

    const [ipms, setIpms] = useState(null);
    const [ipmDialog, setIpmDialog] = useState(false);
    const [deleteIpmDialog, setDeleteIpmDialog] = useState(false);
    const [deleteIpmsDialog, setDeleteIpmsDialog] = useState(false);
    const [ipm, setIpm] = useState(emptyIpm);
    const [selectedIpms, setSelectedIpms] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        const ipmService = new IpmService();
        //ipmService.getProducts().then(data => setIpms(data));
        ipmService.list().then(data => {
            setIpms(data);
            console.log('ipms data', data);
        });
    }, []);

    const openNew = () => {
        setIpm(emptyIpm);
        setSubmitted(false);
        setIpmDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setIpmDialog(false);
    }

    const hideDeleteIpmDialog = () => {
        setDeleteIpmDialog(false);
    }

    const hideDeleteIpmsDialog = () => {
        setDeleteIpmsDialog(false);
    }

    const formIsValid = () => {
        return Boolean(ipm.name.trim());
    }

    const saveIpm = () => {
        setSubmitted(true);
        if (formIsValid()) {
            const ipmService = new IpmService();
            let _ipms = [...ipms];
            let _ipm = { ...ipm };
            if (!ipm.id) {
                ipmService.create(_ipm).then(data => {
                    console.log('data', data);
                    _ipms.push(data);
                    setIpms(_ipms);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Création réussie!', life: 3000 });
                }).catch(error => {
                    console.log(error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Enregistrement échoué', life: 3000 });
                });
            }
            else {
                ipmService.update(ipm.id, _ipm).then(data => {
                    console.log('data', data);
                    const index = findIndexById(ipm.id);
                    _ipms[index] = _ipm;
                    setIpms(_ipms);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Modification réussie!', life: 3000 });
                }).catch(error => {
                    console.log(error);
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Enregistrement échoué', life: 3000 });
                });
            }
            setIpmDialog(false);
            setIpm(emptyIpm);
        }
    }

    const editIpm = (ipm) => {
        setIpm({ ...ipm });
        setIpmDialog(true);
    }

    const confirmDeleteIpm = (ipm) => {
        setIpm(ipm);
        setDeleteIpmDialog(true);
    }

    const deleteIpm = () => {
        const ipmService = new IpmService();
        ipmService.delete(ipm.id).then(data => {
            console.log('data', data);
            let _ipms = ipms.filter(val => val.id !== ipm.id);
            setIpms(_ipms);          
            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Sppression réussie!', life: 3000 });
        }).catch(error => {
            console.log(error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Supression échoué', life: 3000 });
        });
        setDeleteIpmDialog(false);
        setIpm(emptyIpm);  
    }

    const findIndexById = (id) => {
        let index = -1;
        for (let i = 0; i < ipms.length; i++) {
            if (ipms[i].id === id) {
                index = i;
                break;
            }
        }
        return index;
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const confirmDeleteSelected = () => {
        setDeleteIpmsDialog(true);
    }

    const deleteSelectedIpms = () => {
        let _ipms = ipms.filter(val => !selectedIpms.includes(val));
        setIpms(_ipms);
        setDeleteIpmsDialog(false);
        setSelectedIpms(null);
        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Ipm Deleted', life: 3000 });
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _ipm = { ...ipm };
        _ipm[`${name}`] = val;
        setIpm(_ipm);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="New" icon="pi pi-plus" className="p-button-success mr-2" onClick={openNew} />
                    <Button label="Delete" icon="pi pi-trash" className="p-button-danger" onClick={confirmDeleteSelected} disabled={!selectedIpms || !selectedIpms.length} />
                </div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload mode="basic" accept="image/*" maxFileSize={1000000} label="Import" chooseLabel="Import" className="mr-2 inline-block" />
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Nom</span>
                {rowData.name}
            </>
        );
    }

    const phoneBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Téléphone</span>
                {rowData.phone}
            </>
        );
    }

    const addressBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Adresse</span>
                {rowData.address}
            </>
        );
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editIpm(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteIpm(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 className="m-0">Gestion des Ipms</h5>
            <span className="block mt-2 md:mt-0 p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const ipmDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveIpm} />
        </>
    );
    const deleteIpmDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteIpmDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteIpm} />
        </>
    );
    const deleteIpmsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteIpmsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedIpms} />
        </>
    );

    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Toast ref={toast} />
                    <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable ref={dt} value={ipms} selection={selectedIpms} onSelectionChange={(e) => setSelectedIpms(e.value)}
                        dataKey="id" paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} ipms"
                        globalFilter={globalFilter} emptyMessage="No ipms found." header={header} responsiveLayout="scroll">
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="name" header="NOM" sortable body={nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '12rem' }}></Column>
                        <Column field="phone" header="TELEPHONE" body={phoneBodyTemplate} sortable headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                        <Column field="address" header="ADRESSE" sortable body={addressBodyTemplate} headerStyle={{ width: '34%', minWidth: '10rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Dialog visible={ipmDialog} style={{ width: '450px' }} header="Formulaire Ipm" modal className="p-fluid" footer={ipmDialogFooter} onHide={hideDialog}>
                        <div className="formgrid grid">
                            <div className="field col">
                                <label htmlFor="name">Nom</label>
                                <InputText id="name" value={ipm.name} onChange={(e) => onInputChange(e, 'name')} required autoFocus className={classNames({ 'p-invalid': submitted && !ipm.name })} />
                                {submitted && !ipm.name && <small className="p-invalid">Name is required.</small>}
                            </div>
                            <div className="field col">
                                <label htmlFor="phone">Téléphone</label>
                                <InputText id="phone" value={ipm.phone} onChange={(e) => onInputChange(e, 'phone')}  required autoFocus className={classNames({ 'p-invalid': submitted && !ipm.phone })} />
                                {submitted && !ipm.phone && <small className="p-invalid">Phone is required.</small>}
                            </div>
                        </div>
                        <div className="field">
                            <label htmlFor="address">Adresse</label>
                            <InputText id="address" value={ipm.address} onChange={(e) => onInputChange(e, 'address')}  required autoFocus className={classNames({ 'p-invalid': submitted && !ipm.address })} />
                            {submitted && !ipm.address && <small className="p-invalid">Address is required.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteIpmDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteIpmDialogFooter} onHide={hideDeleteIpmDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {ipm && <span>Are you sure you want to delete <b>{ipm.name}</b>?</span>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteIpmsDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteIpmsDialogFooter} onHide={hideDeleteIpmsDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {ipm && <span>Are you sure you want to delete the selected products?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}

const comparisonFn = function (prevProps, nextProps) {
    return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(IpmPage, comparisonFn);
