import axios from 'axios';
import getEnv from '../../common/env';

const API_URL =  `${getEnv().baseUrl}/accounts/my-users`

export default class UserService {
  
  list() {
    return axios
        .get(API_URL)
        .then((response) => {
            //console.log('response', response)
            return response.data;
        });
  }

  create(data) {
      return axios
          .post(`${API_URL}/`, data)
          .then((response) => {
              //console.log('response', response)
              return response.data;
          });
  }

  read(id){
      return axios
          .get(`${API_URL}/${id}/`)
          .then((response) => {
              //console.log('response', response)
              return response.data;
          });
  }

  update(id, data) {
      return axios
          .put(`${API_URL}/${id}/`, data)
          .then((response) => {
              //console.log('response', response)
              return response.data;
          });
  }

  delete(id) {
      return axios
          .delete(`${API_URL}/${id}/`)
          .then((response) => {
              console.log('response', response)
              return response.data;
          });
  }

  getXp() {
    return axios.get('assets/demo/data/products.json').then((res) => res.data.data);
  }

}
